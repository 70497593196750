export default [
  {
    id: 0,
    value: "January"
  },
  {
    id: 1,
    value: "February"
  },
  {
    id: 2,
    value: "March"
  },
  {
    id: 3,
    value: "April"
  },
  {
    id: 4,
    value: "May"
  },
  {
    id: 5,
    value: "June"
  },
  {
    id: 6,
    value: "July"
  },
  {
    id: 7,
    value: "August"
  },
  {
    id: 8,
    value: "September"
  },
  {
    id: 9,
    value: "October"
  },
  {
    id: 10,
    value: "November"
  },
  {
    id: 11,
    value: "December"
  }
];
